.menu-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  margin-bottom: 120px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0 20px;
  width: 320px;
  transform: scale(1.2);
  box-shadow: 0px 10px 40px 0px #18236b33;
}

.menu-bar-horizontal {
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  box-shadow: 0px 10px 40px 0px #18236b33;
}

.eva-hover {
  display: flex;
}

// SPEEDIAL FIX
.MuiSpeedDialAction-staticTooltipLabel {
  width: max-content;
}

.viewer-layout-toolbar {
  display: none !important;
}

@primary-color: #313130;@link-color: #9116E7;@success-color: #2ded7a;@info-color: #7c77b9;@warning-color: #f1d302;@error-color: #ed254e;@font-family: Charlie Display;@layout-header-background: #313130;